const locationReducer = (state = {
    locationName: ""
} , action) => {
    switch (action.type){
        case 'setLocation':
            return {
                locationName: action.location
            }
        case 'DEFAULT':
            return {
                locationName: ""
            }

         default:
           return state
    }
    return state;
}

export default locationReducer