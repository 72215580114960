import React, {useEffect, useState} from 'react';
import BoxMarket from './BoxMarket.js';
import { getDefaultProvider, } from "ethers"
import { NftProvider, useNft } from "use-nft"
import { connect } from 'react-redux';
import { posts } from "./gen-5.json";
import Web3 from 'web3';
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { Flipper, Flipped, spring } from 'react-flip-toolkit'
import audioJump from './audio/Jump.wav'
import useSound from 'use-sound';
const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioCtx = new AudioContext();
const audio_jump = new Audio(audioJump);


const initialState = [];
let items = [];
function Marketplace(props) {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
  }, [])
  const [isFilter, setFilter] = useState(false);
  var data = [...props.nftitems];
  if(isFilter){
    data = [...data].filter(item => item.owner_of == '0xadd95b5da89a1cf3f177b336d27a89dbaa8ef29f')
  }
  const move = () => {
    
  }
  const onClick = () => {
    setFilter(!isFilter)
    audio_jump.currentTime = 0;
    audio_jump.volume = 0.4;
    audio_jump.play();
  }

  const onElementAppear = (el, index) =>
  spring({
    config: "wobbly",
    values: {
      scale: [0, 1],
      opacity: [0, 1]
    },
    onUpdate: ({ scale, opacity }) => {
      el.style.opacity = opacity;
      el.style.transform = `scale(${scale})`;
    },
    delay: 0,
    onComplete: () => {
      // add callback logic here if necessary
    }
  });
  const onExit = type => (el, index, removeElement) => {
    spring({
      config: "wobbly",
      values: {
        scale: [1, 0],
        opacity: [1, 0]
      },
      onUpdate: ({ scale, opacity }) => {
        el.style.opacity = opacity;
        el.style.transform = `scale(${scale})`;
      },
      delay: 0,
      onComplete: () => {
        removeElement(el)
      }
    });
  }

  const onGridExit = onExit('grid')
  const onListExit = onExit('list')
  const { id, title, type, stagger, addToFilteredIds } = props
  return(
    <React.Fragment>
      <React.Fragment>
      <div class="toggleFilter" onClickCapture={() => onClick()}>
      <div className={`switch ${isFilter ? "active" : ""}`}>
        <div class="slider round"></div>
      </div>
      <label>
      {isFilter && "Show Sold Tokens"}
      {!isFilter && "Hide Sold Tokens"}
      </label>
      </div>
      </React.Fragment>
      <Flipper flipKey={data} spring={{ stiffness: 800, damping: 60 }}  stagger opacity={true} applyTransformOrigin translate scale>
      <div class="marketplace_grid">
      {data.length == 0 &&
      <div class="mintingText">
        Working on it, check back later!
      </div>
      }
      {data
      .sort((a, b) => parseInt(a.token_id) < parseInt(b.token_id) ? 1 : -1)
      .map((d) => {
        const meta = JSON.parse(d.metadata);
        const owner = d.owner_of;
        const id = d.token_id;
        if(meta) {
          if(meta.hasOwnProperty('attributes')){
            const attributes = meta.attributes;
            const text = attributes.map(o => o.textclean);
            const price = attributes.map(o => o.price);

        return (
          <Flipped flipId={id} key={id} 
          onExit={onGridExit} onAppear={onElementAppear}
            onStart={el => {el.classList.add("animating")}}
            onComplete={el => {
              setTimeout(() => {
                el.classList.remove("animating");
              }, 5000);}}>
            {flippedProps => <BoxMarket flippedProps={flippedProps} owner={owner} text={text} price={price} 
          id={id}
          nftData={props.transfers
            .filter(item => item.token_id == id)
            .map((item, index) => {
              return (
              <li>
                Token #{item.token_id} was transferred from {item.from_address} to {item.to_address} <br />
                <date>{formatDate(item.block_timestamp)}</date>
              </li>
              )
            })}
          />}
            </Flipped>
        );
        }}
      })}
      </div></Flipper>
      
      
      </React.Fragment>
  );

}
const mapStateToProps = state => {
  return {
    nftitems: state.nft.items,
    transfers: state.nft.transfers

  };
}
export default connect(mapStateToProps)(Marketplace);