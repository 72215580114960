import logo from './logo.svg';
import './App2.css';
import Bar from './Bar';
import React from 'react';
import Box from './Box.js';
import Draggable from 'react-draggable'; 
import {ReactComponent as Sub} from './sub.svg';
import {ReactComponent as Pol} from './pol.svg';
import {ReactComponent as InfoIcon} from './svg/info2.svg';
import { posts } from "./gen-0.json";
import Posts from "./Posts.js"
import Timeline from "./Timeline.js"
import cryptoscape from './cryptoscape.js'
import { TransformWrapper, TransformComponent, Template } from "react-zoom-pan-pinch";
import { connect } from 'react-redux';
import { $, jQuery } from 'jquery';
import Three from './three.js';
import Rechartc from './Rechart';
import polygon from './svg/polygon.svg'
import { ReactComponent as Opensea } from './svg/opensea2.svg'
import { BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect, useLocation, withRouter } from 'react-router-dom';
import Feed from './Feed.js'
import Bin from './Bin.js'
import Marketplace from "./Marketplace.js"
import usePathname from './usePathName.js'
import DynamicMenu from './DynamicMenu';
import ScrollContainer from "react-indiana-drag-scroll";

const HighestScore = {
}

class FeedBar extends React.Component { 
    constructor(props) {
      super(props)
      this.state = {
        siteWidthLocal: 0
      }
    }
    componentDidMount() {
    }
    componentDidUpdate() {
      const pathname = window.location.pathname;
    }
    setNextGen(){
    this.props.dispatch({ type: "setScroll", currentGenScroll: 2 });
    }
    getTimeTillMint(){
      let options = {
        timeZone: 'Europe/Berlin',
        hour: 'numeric',
      },
      formatter = new Intl.DateTimeFormat([], options);
      const time = (new Date()).toLocaleString([], options)
      if(time >= 15) {
        var timeleft = 24 - time + 3;
        return timeleft;
      }
      if(time < 15) {
        var timeleft = 15 - time;
        return timeleft;
      }
    }
  render () {
    const time = this.getTimeTillMint();
    //const withoutSpousePointSum = 
      //this.props.nftitems.reduce((acc, curr) => acc + parseInt(curr.map((d) => {(JSON.parse(d.metadata)).attributes.price})), 0);
   //const totalvolume = this.props.nftitems.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.total_reactions), 0) / 1000
    return (
      <React.Fragment>
        <div class="feedBar">
        <ScrollContainer className="slide">
          <div class="section">
          <Route 
        path="/timeline" 
        render={() => (
          <div class="inner first">
          Total Generations: {this.props.currentGenScroll}
        </div>
        )} />
        <Route 
        path="/info" 
        render={() => (
          <div class="inner first">
          About postBreeder
        </div>
        )} />
        <Route 
        path="/feed" 
        render={() => (
        <div class="inner first">
        Generation {this.props.currentGenScroll}
        </div>      
        )} />  
        <Route 
        path="/marketplace" 
        render={() => (
        <div class="inner first">
        Total Generations: 28
        </div>      
        )} />  
        </div>
        <Route 
        path="/feed" 
        render={() => (
        <div className="section">
        <div className="inner second">
        Last generation minted
        </div>
        </div>
        )} />
        <Route 
        path="/timeline" 
        render={() => (
            <React.Fragment>
        <div className="section">
        <div className="inner second">
        Minted Volume: <div className="img"><img src={polygon} /></div> {this.props.mintedSupply}
        </div>
        </div>
        <div className="section third">
        <div className="inner second">
        Dead Volume: <div className="img"><img src={polygon} /></div> {this.props.deadSupply}
        </div>
        </div>
        </React.Fragment>
        )} />
        <Route 
        path="/marketplace" 
        render={() => (
        <React.Fragment>
            <div className="section">
            <div className="inner third">
          Total Volume: <div className="img"><img src={polygon} /></div> {this.props.totalSupply}
          </div></div>
          </React.Fragment>
        )} 
        />
        <Route 
        path="/feed" 
        render={() => (
        <React.Fragment>
            <div className="section">
            <div className="inner third">
          Current Volume: <div className="img"><img src={polygon} /></div> {this.props.currentSupply}
          </div></div>
          </React.Fragment>
        )} />
        <React.Fragment>
          <NavLink to="/info">
          <div class="info-icon">
          <InfoIcon />
          </div>
          </NavLink>
        </React.Fragment>
        </ScrollContainer>
        </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentGenScroll: state.globalStates.currentGenScroll,
    currentSupply: state.globalStates.currentSupply,
    nftitems: state.nft.items,
    totalSupply: state.nft.totalSupply,
    mintedSupply: state.globalStates.mintedSupply,
    deadSupply: state.globalStates.deadSupply,
  };
}
export default connect(mapStateToProps)(FeedBar);