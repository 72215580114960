import React, {useEffect, useState} from 'react';
import BoxMarket from './BoxMarket.js';
import { getDefaultProvider, } from "ethers"
import { NftProvider, useNft } from "use-nft"
import { connect } from 'react-redux';
import { posts } from "./gen-5.json";
import Web3 from 'web3';
import axios from "axios";
//import { useMoralis, useMoralisWeb3Api } from "react-moralis";

const options = {
  chain: "polygon",
  address: "0x2790c2C4608e38da7C4D9A89CFC9E91B0a0F9366",
};
const apiurl = "https://polygon-mainnet.g.alchemy.com/v2/ViTz3JfjpKWJOQ-gbcKj6oKp3Ot1maa1"
const initialState = [];
let items = [];
function FetchNFT(props)  {
  //const Web3Api = useMoralisWeb3Api()
  //const { web3, isInitialized } = useMoralis();
  const [isFetched, setFetched] = useState(false);
  const [dummyData, setDummyData] = useState([]);
  const [transferData, setTransferData] = useState([]);

  //let items = [];
  const fetchBlock = async() => {
      //const result = await Web3Api.token.getNFTOwners(options);
      const transfers = await axios.post(apiurl, {
        method: 'alchemy_getAssetTransfers',
        url: apiurl,
        responseType: 'stream',
        params: {
          contractAddresses: ["0x2790c2C4608e38da7C4D9A89CFC9E91B0a0F9366"],
          category: ["erc721", "erc1155"],
        }
      })
      .then(function (response) {
          setFetched(true);
          setTransferData(response.data.result.transfers)
          props.dispatch({ type: "setNFTData", items: items, transfers:  response.data.result.transfers});

      });
    //const totalvalue = items.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.total_reactions), 0) / 1000
    //console.log(totalvalue)
    /*const supply = dummyData.reduce((accumulator, currentValue) => accumulator + JSON.parse(currentValue.metadata).price, 0) / 1000*/
      //this.props.dispatch({ type: "setScroll", currentGenScroll: currentGen, currentSupply: supply });
    console.log(items)
  }
  const getAllPrices = () => {
    let totalPrices = 0;
    const items = props.nftitems;
    items
      .map((d) => {
        const meta = JSON.parse(d.metadata);
        const owner = d.owner_of;
        const id = d.token_id;
        if(meta) {
          if(meta.hasOwnProperty('attributes')){
            const attributes = meta.attributes;
            const price = attributes.map(o => o.price);
            totalPrices += parseFloat(price);
          }
        }
      })
      props.dispatch({ type: "setNFTData", totalSupply: totalPrices.toFixed(5), items: props.nftitems, transfers: transferData });
      
  }
  useEffect(() => {
    if(!isFetched){
    fetchBlock();
    }
    if(isFetched){
      getAllPrices();
    }
  });
  return false
}
const mapStateToProps = state => {
    return {
        nftitems: state.nft.items,
        transfers: state.nft.transfers,
  
    };
  }
  export default connect(mapStateToProps)(FetchNFT);