const nftReducer = (state = {
    items: [],
    transfers: [],
    totalSupply: 0,
    isFetched: false
} , action) => {
    switch (action.type){
        case 'setNFTData':
            return {
                items: action.items,
                transfers: action.transfers,
                isFetched: true,
                totalSupply: action.totalSupply
            }
        case 'DEFAULT':
            return {
                items: [],
                transfers: []
            }

         default:
           return state
    }
    return state;
}

export default nftReducer