import logo from './logo.svg';
import './App2.css';
import Bar from './Bar';
import React from 'react';
import Posts from "./Posts.js"
import Warning from "./Warning.js"
import { connect } from 'react-redux';
import { compose } from 'redux';
import {ReactComponent as DeployIcon} from './svg/deploy2-01.svg';
import {ReactComponent as TransferIcon} from './svg/transfer2-03.svg';
import { BrowserRouter as Router, Link, NavLink, withRouter } from 'react-router-dom';

class Info extends React.Component { 
   
    constructor(props) {
      super(props)
      this.state = {
        siteWidthLocal: 0,
        locationNameLocal: ""
      }
    }
    
    componentDidMount() {
      window.scrollTo(0, 0)
    }
    
  render () {
    return (
      <React.Fragment>
        <div class="infoPage">
        postBreeder by <a target="_blank" href="https://instagram.com/jakobsitter">Jakob Sitter</a> is a generative platform fuelled by a genetic algorithm and a natural language processing model. The base model is trained on data from the 100 most popular pages on Facebook, and is re-trained every 12 hours on the most successful posts from the previous generation in terms of reactions. Visitors are invited to alter the feed by pressing the like/dislike buttons, thus being able to influence the creation of future content and the corresponding ranking system.
        The posts that makes it into the next training set are minted as NFTs onto the Polygon blockchain, and can be bought in the <NavLink to="/marketplace">Marketplace</NavLink> and on <a target="_blank" href="https://opensea.io/collection/postbreeder">OpenSea</a>.
        <br /><br />Sound effects by <a target="_blank" href="https://fxyz.fun/">Fabian Lörz</a>.
<br /><br />
The project was commissioned by <a target="_blank" href="https://kunstverein.de">Kunstverein in Hamburg</a>.
</div>
        </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    locationName: state.location.locationName,
    nftItems: state.nft.items,
    nftTransfers: state.nft.transfers
  };
}
export default withRouter(connect(mapStateToProps)(Info));
