import React from 'react';
import { ScrollingProvider, useScrollSections, Section } from 'react-scroll-section';
import { connect } from 'react-redux';
import { useState, useEffect, useCallback, useRef } from 'react';

const DynamicMenu = (props) => {
  const sections = useScrollSections();
    const curId = props.currentGenScroll
    const activeObj = sections.find(obj => obj.selected == true);
    const [isActive, setActive] = useState("false");
    const [sectionData, setSectionData] = useState([])

    //activeObj?.onClick()
    if(activeObj){
      props.dispatch({ type: "setScroll", currentGenScroll: activeObj.id });
    }
    const [data, setData] = useState([])
    function setNextGen(){
      const activeObj = sections.find(obj => obj.selected == true);
      const nextId = parseInt(activeObj.id) + 1;
      console.log(nextId)
      const nextObj = sections.find(obj => obj.id == nextId)
      if(nextObj){
      nextObj.onClick();
      }
    }
    function setPrevGen(){
      const activeObj = sections.find(obj => obj.selected == true);
      const nextId = parseInt(activeObj.id) - 1;
      console.log(nextId)
      const nextObj = sections.find(obj => obj.id == nextId)
      if(nextObj){
      nextObj.onClick();
      }
    }
    function toggleMenu(){
      //setActive(!isActive);
    }
    const sections2 = useCallback(() => {
      return sections
    });
    const numGens = sections.length
    useEffect(() => {
      if(activeObj){
      //props.dispatch({ type: "setScroll", currentGenScroll: activeObj.id, scrollSections: sections });
      }
    }, [activeObj])
    return (
        <ul className={isActive ? "timeline_menu" : "timeline_menu toggle"}>
          <div class="nav">
          <div class="up" onClick={setNextGen}></div>
          <div class="down" onClick={setPrevGen}></div>
          </div>
          {sections.length == 0 && "Generation 0 / 0"}
          {sections.map(({ id, onClick, selected }, index) => (
            <li key={id} onClick={!selected && onClick || selected && toggleMenu} selected={selected} className={selected && "active"}>
              Generation {id.toUpperCase()} / {numGens}
            </li>
          ))}
        </ul>
      );
  };
  const mapStateToProps = state => {
    return {
      currentGenScroll: state.globalStates.currentGenScroll,
      scrollSections: state.globalStates.scrollSections
    };
}
  export default connect(mapStateToProps)(DynamicMenu);