import * as THREE from 'three';
import { ThreeJSR } from '@edwmurph/threejsr';

import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

class Sphere extends ThreeJSR {
  constructor (ref, newFrameHook) {
    const bloomPass = new UnrealBloomPass();
    super(ref, newFrameHook, { passes: [bloomPass] });
  }

  renderNextFrame({ spin, timestamp }) {
    if (spin) {
      this.mesh.rotation.x += 0.001;
      this.mesh.rotation.y += 0.001;
    }

    return super.renderNextFrame(timestamp);
  }

  createThreeScene() {
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(75, 0, 0.1, 1000);
    this.camera.position.z = 100;

    const geometry = new THREE.SphereGeometry(40, 50, 30);
    const material = new THREE.MeshLambertMaterial({ color: 0xffffff, wireframe: true });

    this.mesh = new THREE.Mesh(geometry, material);
    this.scene.add(this.mesh);

    const spotLight = new THREE.SpotLight(0xffffff);
    spotLight.position.set(100, 10, 100);
    this.scene.add(spotLight);
  }
}

export default new Sphere();