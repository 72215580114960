import logo from './logo.svg';
import './App2.css';
import Bar from './Bar';
import React from 'react';
import Box from './Box.js';
import Draggable from 'react-draggable'; 
import {ReactComponent as Sub} from './sub.svg';
import {ReactComponent as Pol} from './pol.svg';
import { posts } from "./gen-0.json";
import Posts from "./Posts.js"
import { TransformWrapper, TransformComponent, Template } from "react-zoom-pan-pinch";
import { connect } from 'react-redux';
import 'jquery';
import { BrowserRouter as Router, Link, Route, withRouter } from 'react-router-dom';

const HighestScore = {
}
class Bin extends React.Component { 
   
    constructor(props) {
      super(props)
      this.state = {
        siteWidthLocal: 0,
        locationNameLocal: ""
      }
    this.siteDiv = React.createRef(); //newRef is now available for use throughout our component
    }
    componentDidMount() {
        var location2 = this.props.location.pathname;
            location2 = location2.replace('/', '');
      this.setState({
        siteWidthLocal : this.siteDiv.current.offsetWidth,
        siteHeightLocal : this.siteDiv.current.offsetHeight,
        locationNameLocal : location2

      }, () => {
        this.props.dispatch({ type: "SetSiteSize", width: this.state.siteWidthLocal, height: this.state.siteHeightLocal });
        this.props.dispatch({ type: "setLocation", location: this.state.locationNameLocal });
      });
    }
  render () {
    return (
        <TransformWrapper initialScale={2} maxScale={13} centerOnInit={true}      wheel={{
            step: 0.05
          }}
          panning={{
            velocityDisabled: false
          }} doubleClick={{disabled: true}}>
            {({ zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => (
              <React.Fragment>
                   <div className="tools">
                  <button onClick={() => zoomIn()}>+</button>
                  <button onClick={() => zoomOut()}>-</button>
                  <button onClick={() => resetTransform()}>x</button>
                </div>
                <TransformComponent>
            <div class="grid" ref={this.siteDiv}>
            <Posts siteDiv={this.getSite}  />
            </div>
            </TransformComponent>
            </React.Fragment>
            )}
            </TransformWrapper>
    );
  }
}
const mapStateToProps = state => {
  return {
    locationName: state.location.locationName,

  };
}
export default withRouter(connect(mapStateToProps)(Bin));
