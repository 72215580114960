import React from 'react';
import Box from './Box.js';
import { generations } from "./timeline.json";
import { connect } from 'react-redux';
import { useTransition, animated } from "react-spring";
import { Flipper, Flipped, spring } from 'react-flip-toolkit'
import useSound from 'use-sound';
import hoverAudio from './audio/FabiUI1.m4a'
import { ScrollingProvider, useScrollSections, Section } from 'react-scroll-section';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import _ from 'lodash';
import DynamicMenu from './DynamicMenu.js';
import Scrollspy from 'react-scrollspy'
import Polygon from './svg/polygon.svg'
import Masonry from 'react-masonry-css'

import Stat1 from './Rechart.js';
const audio = new Audio(hoverAudio);
const breakpointColumnsObj = {
  default: 5,
  2000: 4,
  1500: 3,
  1100: 2,
  812: 1
};
class Timeline extends React.Component {  
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      numGenerations: 0,
      items2: [],
      isLoaded: false,
      reload: false,
      currentSection: "0",
      deadSupply: 0,
      mintedSupply: 0
    }
  }
  
  componentDidMount(){
    this.fetchPosts();

   }
  fetchPosts(){
    fetch('https://www.pb-server.jakobsitter.com/api/collections/get/postBreeder?token=adfae1379cafa1a8fa66c52f16b416')
      .then(response => response.json())
      .then(data => this.setState({ items: data.entries }));
      /*this.setState({
        items: (posts)
      });*/
      /*let copyState = this.state.items.slice();
        copyState.forEach((item, index) => {
          const leftPos = (Math.random() * (this.props.siteWidth - 125)) * 4;
          const topPos = (Math.random() * (this.props.siteHeight - 125)) * 4;
          item[index] = [...item[index], {new: "yes"];
        })*/
        this.setState((prevState) => {
          let temp = {
            ...prevState,
            items: [...prevState.items],
            isLoaded: true
          }
          temp.items.forEach((item, index) => {
            temp.items[index].id = index;
            temp.items[index].initScore = temp.items[index].total_reactions;
          })
        
          return temp
        }, () => {
          setTimeout(() => {
            window.scrollTo(0, 1)
          }, 500)
      });
  }
  
  move = () => {
    audio.play();
  }
  statData() {
    const initData = this.state.items
    return initData;
  }
  countMinted() {
    const data = _.groupBy(this.state.items, 'gen');
    var mintedReactions = 0;
    var deadReactions = 0;
    Object.keys(data)
      .sort((a, b) => parseInt(a) < parseInt(b) ? 1 : -1)
      .map(function(division,key){
        data[division]
              .sort((a, b) => parseInt(a.total_reactions) < parseInt(b.total_reactions) ? 1 : -1)
              .map(function(item, key) {
                const numPosts = data[division].length / 2
                if(key >= numPosts) {
                  deadReactions += parseInt(item.total_reactions)
                } else {
                  mintedReactions += parseInt(item.total_reactions)
                }
        })
      })
    //this.setState({deadSupply: deadReactions, mintedSupply: mintedReactions});    
      return (mintedReactions / 10000).toFixed(5)

  }
  countDead() {
    const data = _.groupBy(this.state.items, 'gen');
    var mintedReactions = 0;
    var deadReactions = 0;
    Object.keys(data)
      .sort((a, b) => parseInt(a) < parseInt(b) ? 1 : -1)
      .map(function(division,key){
        data[division]
              .sort((a, b) => parseInt(a.total_reactions) < parseInt(b.total_reactions) ? 1 : -1)
              .map(function(item, key) {
                const numPosts = data[division].length / 2
                if(key >= numPosts) {
                  deadReactions += parseInt(item.total_reactions)
                } else {
                  mintedReactions += parseInt(item.total_reactions)
                }
        })
      })
    //this.setState({deadSupply: deadReactions, mintedSupply: mintedReactions});    
      return (deadReactions / 10000).toFixed(5)

  }
  countData() {
    const data = _.groupBy(this.state.items, 'gen');
    const data2 = this.state.items;
    const arrayLength = Object.keys(data).length;
    console.log(data)

    var total = new Array(arrayLength).fill(0);
    for(var i = 0; i < data2.length; i++){
        var gen = parseInt(data2[i].gen)
        total[gen] += parseInt(data2[i].total_reactions);

    }
    const newObject = []
    var mintedReactions = 0;
    var deadReactions = 0;
    Object.keys(data)
      .sort((a, b) => parseInt(a) < parseInt(b) ? 1 : -1)
      .map(function(division,key){
        console.log(division)
        var reactions = 0;
        var likes = 0;
        var wow = 0;
        var angry = 0;
        var sad = 0;
        var haha = 0;
        var love = 0;
        data[division]
              .sort((a, b) => parseInt(a.total_reactions) < parseInt(b.total_reactions) ? 1 : -1)
              .map(function(item, key) {
                const numPosts = data[division].length / 2
                if(key >= numPosts) {
                  deadReactions += parseInt(item.total_reactions)
                } else {
                  deadReactions += parseInt(item.total_reactions)
                }
                reactions += parseInt(item.total_reactions)
                love += parseInt(item.love)
                likes += parseInt(item.likes)
                wow += parseInt(item.wow)
                angry += parseInt(item.angry)
                sad += parseInt(item.sad)
        })
        var gen = division;
        const totals = {reactions, love, likes, wow, angry, sad, gen}
        newObject.push(totals)
      })
    console.log(newObject)
      /*if(this.props.currentGenScroll < 3){
        return total.slice(0, 3)
      }
      else {
        return total.slice(0, this.props.currentGenScroll)
      }*/
      return newObject.reverse()
  }
  

render(){
  const { error, isLoaded, items } = this.state;
  const currentGen = Math.max.apply(Math, items.map(function(o) { return parseInt(o.gen); }));
  const mintedSupply = this.countMinted();
  const deadSupply = this.countDead();
  this.props.dispatch({ type: "setScroll", currentGenScroll: currentGen, deadSupply: deadSupply, mintedSupply: mintedSupply });
  const data = this.countData(currentGen);
  const items3 = _.groupBy(items, 'gen');
  console.log(items3)
    return (
     <React.Fragment>
       <Stat1 data={this.countData()} />
       <div className="timeline_postsection">
      {Object.keys(items3)
      .sort((a, b) => parseInt(a) < parseInt(b) ? 1 : -1)
      .map(function(division,key){
        const volume = items3[division].reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.total_reactions), 0) / 10000;
          return(
          <Section id={division}>
                        <div class="title">Generation {division} <img src={Polygon} /> {volume}</div>
                        <Masonry
  breakpointCols={breakpointColumnsObj}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
          { 
              items3[division]
              .sort((a, b) => parseInt(a.total_reactions) < parseInt(b.total_reactions) ? 1 : -1)
              //.slice(0, items3[division].length / 2)
              .map(function(item, key) {
                const numPosts = items3[division].length / 2
                const totPosts = items3[division].length

                return (
                    <React.Fragment>
                  
                  <Box timeline_item={true} isDead={key >= numPosts && "True"} reactEnabled={false} text={item.message} 
                  zindex={item.total_reactions} 
                  index={item._id}
                  imageBool={item.image} />
                
                  </React.Fragment>
                  );
              })
          }
          </Masonry>
          </Section>);
      })}
      </div>
          </React.Fragment>
    );
  }
}
  const mapStateToProps = state => {
    return {
      currentGenScroll: state.globalStates.currentGenScroll,
      mintedSupply: state.globalStates.mintedSupply,
      deadSupply: state.globalStates.deadSupply

    };
  }
  export default connect(mapStateToProps)(Timeline);