import React from 'react';
import Box from './Box.js';
import { posts } from "./gen-5.json";
import { connect } from 'react-redux';
import { useTransition, animated } from "react-spring";
import { Flipper, Flipped, spring } from 'react-flip-toolkit'
import useSound from 'use-sound';
import audioUp from './audio/Up.wav'
import audioDown from './audio/Down.wav'
import audioWin from './audio/Joy.wav'
const HighestVal = Math.max.apply(Math, posts.map(function(o) { return o.total_reactions; }));
function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
 } 
 function bluriness(partialValue, totalValue) {
  return totalValue - partialValue;
} 
const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioCtx = new AudioContext();
const audio_up = new Audio(audioUp);
const audio_down = new Audio(audioDown);
const audio_win = new Audio(audioWin);

class Posts extends React.Component {  
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      isLoaded: true,
      reload: false,
      isMoving: false,
      movedirection: "up"
    }
    this.move = this.move.bind(this)
  }
  componentDidMount(){
    this.fetchPosts();
  }
  fetchPosts(){
    fetch('https://www.pb-server.jakobsitter.com/api/collections/get/postBreeder?token=adfae1379cafa1a8fa66c52f16b416')
      .then(response => response.json())
      .then(data => this.setState({ items: data.entries }));
      /*this.setState({
        items: (posts)
      });*/
      /*let copyState = this.state.items.slice();
        copyState.forEach((item, index) => {
          const leftPos = (Math.random() * (this.props.siteWidth - 125)) * 4;
          const topPos = (Math.random() * (this.props.siteHeight - 125)) * 4;
          item[index] = [...item[index], {new: "yes"];
        })*/
        this.setState((prevState) => {
          let temp = {
            ...prevState,
            items: [...prevState.items]
          }
          temp.items.forEach((item, index) => {
            temp.items[index].id = index;
            temp.items[index].initScore = temp.items[index].total_reactions;
          })
        
          return temp
        })
    
 
  }

  passedFunction = (index, typeofreaction) => {
    //this.props.zindex = 10;
    //console.log(this.state.items.find(x => x.id === index).total_reactions)
    //const { items } = this.state;
        let items = [...this.state.items]
        const currentGen = Math.max.apply(Math, items.map(function(o) { return o.gen; }));
        const maxReactions = Math.max.apply(Math, items.filter(item => item.gen == parseInt(currentGen)).map(function(o) { return o.total_reactions; }));
        console.log(maxReactions)
        let item = items.find(x => x._id === index);
        /*if(item.total_reactions >= maxReactions) {
          alert("on top")
        }*/
        if(typeofreaction == "dislike"){
          item.total_reactions = +item.total_reactions - 1;
          this.setState({movedirection: "down"})
        }
        else{
        item[typeofreaction] = +item[typeofreaction] + 1;
        item.total_reactions = +item.total_reactions + 1;
        const newScore = item[typeofreaction];
        this.setState({movedirection: "up"})
        }
        const newScoreTotal = item.total_reactions;
        let obj = items.find(x => x._id === index)
        obj = item;
        //newArray[index] = {...newArray[index], [typeofreaction] : +newArray[index][typeofreaction] + 1, total_reactions: +newArray[index].total_reactions + 1}
        this.setState({
          items
        });
        setTimeout(() => {
          if(this.state.isMoving){
          this.move();
          }
        }, 1)
        fetch('https://jakob-sitter.kunstverein.de/cockpit/api/collections/save/postBreeder?token=5dc06d5f6dc5bb168915f64a52ab9d', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              data: {
                _id: index, 
                //[typeofreaction]: newScore,
                total_reactions: newScoreTotal
              }
          })
        })
        .then(res=>res.json())
        .then(entry => console.log(entry));
        
      }
  move = () => {
    if(this.state.movedirection == "up") {
      audio_up.currentTime = 0;
      audio_up.volume = 0.4;
      audio_up.play();
    }
    if(this.state.movedirection == "down") {
      audio_down.currentTime = 0;
      audio_down.volume = 0.4;
      audio_down.play();
      }
  }
render(){
  const { error, isLoaded, items } = this.state;
  console.log(this.state.isMoving)
  const currentGen = Math.max.apply(Math, items.map(function(o) { return o.gen; }));
  const supply = items.filter(item => item.gen == parseInt(currentGen)).reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.total_reactions), 0) / 10000
  this.props.dispatch({ type: "setScroll", currentGenScroll: currentGen, currentSupply: supply.toFixed(5) });
    return (
      <Flipper flipKey={items} spring={{ stiffness: 200, damping: 50 }} spring='wobbly' stagger scale decisionData>
          {items
            .filter(item => item.gen == parseInt(currentGen))
            .sort((a, b) => parseInt(a.total_reactions) < parseInt(b.total_reactions) ? 1 : -1)
            .map((item, index) => {
            return (
            <Flipped flipId={item._id} key={item._id} 
            onStartImmediate={() => this.setState({isMoving:true})}
            onComplete={() => this.setState({isMoving:false})}>
            {flippedProps => <Box reactEnabled={true} flippedProps={flippedProps} text={item.message} 
            zindex={item.total_reactions} 
            index={item._id}
            passedFunction={this.passedFunction}
            imageBool={item.image} />}
            </Flipped>
            );
          })}
          </Flipper>
    );
  }
}
  const mapStateToProps = state => {
    return {
      currentGenScroll: state.globalStates.currentGenScroll,
      currentSupply: state.globalStates.currentSupply
    };
  }
  export default connect(mapStateToProps)(Posts);