import Sphere from './sphere.js';
import { ThreeJSRComponent } from '@edwmurph/threejsr';
import React from 'react';

class Three extends React.Component { 
  render () {
    const renderLoopData = {
      spin: true
    };
    return (
      <ThreeJSRComponent
      ThreeJSR={Sphere}
      renderLoopData={renderLoopData}
      style={{ border: '5px solid green' }}
    />
    );
  }
}
export default Three;