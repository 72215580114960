const globalStateReducer = (state = {
    currentGenScroll: 0,
    currentSupply: 0,
    mintedSupply: 0,
    deadSupply: 0
} , action) => {
    switch (action.type){
        case 'setScroll':
            return {
                currentGenScroll: action.currentGenScroll,
                currentSupply: action.currentSupply,
                scrollSections: action.scrollSections,
                mintedSupply: action.mintedSupply,
                deadSupply: action.deadSupply
            }
        case 'DEFAULT':
            return {
                currentGenScroll: 0
            }

         default:
           return state
    }
    return state;
}

export default globalStateReducer