import Draggable from 'react-draggable'; 
import {ReactComponent as Sub} from './sub.svg';
import {ReactComponent as Pol} from './pol.svg';
import React from 'react';
import { connect } from 'react-redux';
import useSound from 'use-sound';
import hoverAudio from './audio/UI5.wav'
import {ReactComponent as Like} from './reactionicons/like.svg';
import {ReactComponent as Love} from './reactionicons/love.svg';
import likeclean from './svg/like-clean.svg'
import polygon from './svg/polygon.svg'
import love from './png/love.png'
import like from './png/like.png'
import angry from './png/angry.png'
import haha from './png/haha.png'
import sad from './png/sad.png'
import wow from './png/wow.png'
const audio = new Audio(hoverAudio);
class BoxMarket extends React.Component {  
    constructor(props) {
        super(props)
        this.state = {
          play: false,
          styles: {
              top: 0,
              left: 0
          },
          hover: false,
          height: 0,
          toggleText: false
        }
        this.textInner = React.createRef()
        this.divBox = React.createRef()
        this.expandText = this.expandText.bind(this);
        this.closeText = this.closeText.bind(this);
        this.setHover = this.setHover.bind(this);

      }
      playAudio = () => {
        audio.play();
      }
      stopAudio = () => {
        audio.pause();
        audio.currentTime = 0;
      }
      componentDidMount() {
        /*this.interval = setInterval(() => this.setState({ 
            styles: {
                "top": Math.random() * (this.props.siteHeight - 350),
                "left": Math.random() * (this.props.siteWidth - 705)
              }
        }), 20000);*/
      const textHeight = this.textInner.current.clientHeight;
      this.setState({height: textHeight})

        
    }
    componentWillUnmount() {
    }
    expandText(){
      this.setState({toggleText: true})
    }
    closeText(){
      setTimeout(() => {
        this.setState({toggleText: false})
      }, 200);
    }
    setHover(){
      this.setState({hover: true})
    }
    render() {
      const style = {
                /*"left": this.props.leftPos,
                "top": this.props.topPos,*/
                /*"transform": "scale("+this.props.zoom / 2+")"*/
      }
      return (
        <div class={`box marketver ${this.props.owner !== "0xadd95b5da89a1cf3f177b336d27a89dbaa8ef29f" && "sold"} ${this.state.toggleText && "toggleOpen"} `} {...this.props.flippedProps} ref={this.divBox} onMouseLeave={this.closeText} onMouseOver={this.setHover}>
          {this.props.owner !== "0xadd95b5da89a1cf3f177b336d27a89dbaa8ef29f" &&
          <div className="owner"><bold>#{this.props.id - 2}</bold> owned by <bold>{this.props.owner}</bold></div>
         }
         {this.props.owner == "0xadd95b5da89a1cf3f177b336d27a89dbaa8ef29f" &&
          <div className="owner"><bold>#{this.props.id}</bold></div>
         }
        <div className="message-text">
          <div className="message-text-inner" ref={this.textInner}>
          {this.props.text}
          </div>
        </div>
        <div className='togglePlaceholder'>
        {this.state.height > 100 &&
          <div className='toggleHeight' onClick={this.expandText}>
            <expandbutton>Expand</expandbutton>
            </div>
          }
        </div>
        {this.props.imageBool === true &&
        <React.Fragment>
          <div class="box_image">
            <img src="https://media0.faz.net/ppmedia/aktuell/4167171134/1.7608612/format_top1_breit/mark-zuckerberg-spricht.jpg"></img>
          </div>
        </React.Fragment>
        }
        <div className="metabar">
          <div class="inner">
        <div class="reactions_count">
            <div class='icon'>
                <img src={polygon} />
            </div>
      
        <div class='number'>{this.props.price}</div>
        </div>
        </div>
        {this.props.owner == "0xadd95b5da89a1cf3f177b336d27a89dbaa8ef29f" &&
        <a href={`https://opensea.io/assets/matic/0x2790c2C4608e38da7C4D9A89CFC9E91B0a0F9366/`+this.props.id} target="_blank">
        <div className="buy_button">
          <div className="inner">Buy on OpenSea</div>
        </div>
        </a>
        }
        {this.props.owner !== "0xadd95b5da89a1cf3f177b336d27a89dbaa8ef29f" &&
        <a href={`https://opensea.io/assets/matic/0x2790c2C4608e38da7C4D9A89CFC9E91B0a0F9366/`+this.props.id} target="_blank">
        <div className="buy_button">
          <div className="inner">View on OpenSea</div>
        </div>
        </a>
        }
        </div>
        <div className="data_log">
        {this.props.nftData}
        </div>
        </div>

        );
    }
  }
  const mapStateToProps = state => {
    return {
        siteWidth: state.globalStates.siteWidth,
        siteHeight: state.globalStates.siteHeight,
        siteSizeSet: state.globalStates.siteSizeSet
    };
  }
  export default connect(mapStateToProps)(BoxMarket);