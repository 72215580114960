import logo from './logo.svg';
import './App2.css';
import Bar from './Bar';
import React from 'react';
import Box from './Box.js';
import Draggable from 'react-draggable'; 
import {ReactComponent as Sub} from './sub.svg';
import {ReactComponent as Pol} from './pol.svg';
import { posts } from "./gen-0.json";
import Posts from "./Posts.js"
import Timeline from "./Timeline.js"
import cryptoscape from './cryptoscape.js'
import { TransformWrapper, TransformComponent, Template } from "react-zoom-pan-pinch";
import { connect } from 'react-redux';
import { $, jQuery } from 'jquery';
import Three from './three.js';
import Rechartc from './Rechart';
import polygon from './svg/polygon.svg'
import { ReactComponent as Opensea } from './svg/opensea2.svg'
import { BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect, useLocation, withRouter } from 'react-router-dom';
import Feed from './Feed.js'
import Bin from './Bin.js'
import Info from './Info.js'
import Marketplace from "./Marketplace.js"
import usePathname from './usePathName.js'
import FeedBar from './FeedBar.js'
import { ScrollingProvider, useScrollSections, Section } from 'react-scroll-section';
import FetchNFT from './FetchNFT.js'

const HighestScore = {
}

class App extends React.Component { 
   
    constructor(props) {
      super(props)
      this.state = {
        siteWidthLocal: 0
      }
    }
    componentDidMount() {
    }
    componentDidUpdate() {
      const pathname = window.location.pathname;
      console.log(pathname)
    }
    setNextGen(){
    }
  render () {
    const renderLoopData = {
      spin: true
    };
    const currentURL = window.location.href; // returns the absolute URL of a page
    const pathname = window.location.pathname;
    return (
      <Router>
        <Switch>
        <ScrollingProvider scrollBehavior="smooth" debounceDelay={0} offset={0}>
    <div className={"site "}>
      <Bar />
      <Route exact path="/">
        <Redirect to="/feed" />
      </Route>
      <div className="main">
      <FeedBar />
      {!this.props.isFetched && 
      <div class="global_loader">
        Connecting to the Polygon chain...
      </div>
      }
      {this.props.isFetched &&
      <React.Fragment>
      <Route path="/feed" component={Feed}/>
      <Route path="/marketplace" component={Marketplace}/>
      <Route path="/timeline" component={Timeline}/>
      <Route path="/info" component={Info}/>
      </React.Fragment>
      }
      </div>
    </div>
    <usePathname />
    <FetchNFT />
    </ScrollingProvider>
    </Switch>
    </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentGenScroll: state.globalStates.currentGenScroll,
    currentSupply: state.globalStates.currentSupply,
    isFetched: state.nft.isFetched
  };
}
export default connect(mapStateToProps)(App);