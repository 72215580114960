import logo from './logo.svg';
import './App2.css';
import Bar from './Bar';
import React from 'react';
import Posts from "./Posts.js"
import Warning from "./Warning.js"
import { connect } from 'react-redux';
import { compose } from 'redux';
import {ReactComponent as DeployIcon} from './svg/deploy2-01.svg';
import {ReactComponent as TransferIcon} from './svg/transfer2-03.svg';
import { BrowserRouter as Router, Link, Route, withRouter } from 'react-router-dom';
class Feed extends React.Component { 
   
    constructor(props) {
      super(props)
      this.state = {
        siteWidthLocal: 0,
        locationNameLocal: ""
      }
    }
    scrollToBottom = () => {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    componentDidMount() {
      window.scrollTo(0, 0)
      this.scrollToBottom();
    }
    
  render () {
    return (
      <React.Fragment>
        <div class="feedInner">
        <div class="grid">
        <Posts />
        </div>
        <div class="marketplace_activity_outer">
        <div class="gradient"></div>
        <div class="marketplace_activity">
        <ul>
        {this.props.nftTransfers
      .slice(0, 50)
      .sort((a, b) => a.block_timestamp > b.block_timestamp ? 1 : -1)
      .map((d) => {
        const from = d.from
        const to = d.to
        return (
          <a href={`https://polygonscan.com/tx/`+d.hash} target="_blank">
          <li>
          {from == "0x0000000000000000000000000000000000000000" &&
          <div class="icon"><DeployIcon /></div>
          }
          {from != "0x0000000000000000000000000000000000000000" &&
          <div class="icon"><TransferIcon /></div>
          }
          <bold>#{d.token_id}</bold> was transferred from {from} to {to}
          
          </li></a>
        );
        })}
        </ul>
        <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
        </div>
      </div>
      </div>
        </div>
        </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    locationName: state.location.locationName,
    nftItems: state.nft.items,
    nftTransfers: state.nft.transfers
  };
}
export default withRouter(connect(mapStateToProps)(Feed));
