import React, { useRef, useState, useContext } from 'react'
import { connect } from 'react-redux';

class Warning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {userNameSet: false};

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){

  }
  handleSubmit(event) {
      this.setState({ userNameSet: true });
      event.preventDefault();
  }
render(){
  return (
    
    <React.Fragment>

       <div class={this.state.userNameSet ? "loginpopup hide" : "loginpopup not"}> 
       Still under development. Official launch on the 10th of January.<br />
       <form onSubmit={this.handleSubmit}>
      <input value="OK" type="submit"></input>
      </form>
      </div>
      <div class={this.state.userNameSet ? "bgoverlay hide" : "bgoverlay not"}></div>
  </React.Fragment>
);
}};
function mapStateToProps(state) {
  return {


  };
}
export default connect(mapStateToProps)(Warning);
