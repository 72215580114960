import Draggable from 'react-draggable'; 
import {ReactComponent as Sub} from './sub.svg';
import {ReactComponent as Pol} from './pol.svg';
import React from 'react';
import { connect } from 'react-redux';
import {ReactComponent as Like} from './reactionicons/like.svg';
import {ReactComponent as Love} from './reactionicons/love.svg';
import likeclean from './svg/like-clean.svg'
import polygon from './svg/polygon.svg'
import love from './png/love.png'
import like from './png/like.png'
import angry from './png/angry.png'
import haha from './png/haha.png'
import sad from './png/sad.png'
import wow from './png/wow.png'
class Box extends React.Component {  
    constructor(props) {
        super(props)
        this.state = {
          play: false,
          styles: {
              top: 0,
              left: 0
          },
          hover: false,
          zindex: 0,
          expand: false
        }
        this.expandBox = this.expandBox.bind(this);
        this.divBox = React.createRef()
      }
    
      componentDidMount() {
        /*this.interval = setInterval(() => this.setState({ 
            styles: {
                "top": Math.random() * (this.props.siteHeight - 350),
                "left": Math.random() * (this.props.siteWidth - 705)
              }
        }), 20000);*/
        
    }
    componentWillUnmount() {
    }
    onHover() {
      this.setState({hover:true});
    }
    onLeave() {
      this.setState({hover:false});
    }
    expandBox() {
      this.setState({expand:!this.state.expand});
    }
    render() {
      const style = {
                /*"left": this.props.leftPos,
                "top": this.props.topPos,*/
                "z-index": this.props.zindex.toString()
                /*"opacity" : this.props.opacity+'%',
                "filter" : "blur("+(this.props.blur)+"px)",
                /*"transform": "scale("+this.props.zoom / 2+")"*/
      }
      const price = parseInt(this.props.zindex) / 10000;
      const hoverState = this.state.hover;
      return (
        <div className={`box ${ hoverState && "hover"} ${ this.state.expand && "focus"} ${ this.props.isDead && "dead"} ${ this.props.reactEnabled && "react_enabled"}`} {...this.props.flippedProps} ref={this.divBox} onClick={this.expandBox}>
        <message-text>{this.props.text}</message-text>
        {this.props.imageBool === true &&
        <React.Fragment>
          <div class="box_image">
            <img src="/img.png"></img>
          </div>
        </React.Fragment>
        }
        {this.props.gen}
        <div class="reactions_count">
            <div class='icon'>
                <img src={polygon} />
            </div>
      
        <div class='number'>{price}</div>
        </div>
        {this.props.reactEnabled == true &&
        <div class="action_bar" onMouseEnter={() => this.onHover()}
      onMouseLeave={() => this.onLeave()}>
            <div class="action_hover">
                <div class="item like" onClick={() => {this.props.passedFunction(this.props.index, "likes"); this.onLeave();}}>
                  <div class="label">Like</div>
                    <img src={like} />
                </div>
                <div class="item love" onClick={() => {this.props.passedFunction(this.props.index, "love"); this.onLeave();}}>
                  <div class="label">Love</div>
                    <img src={love} />
                    
                </div>
                <div class="item haha" onClick={() => {this.props.passedFunction(this.props.index, "haha"); this.onLeave();}}>
                  <div class="label">Haha</div>
                    <img src={haha} />
                </div>
                <div class="item wow" onClick={() => {this.props.passedFunction(this.props.index, "wow"); this.onLeave();}}>
                  <div class="label">Wow</div>
                    <img src={wow} />
                </div>
                <div class="item sad" onClick={() => {this.props.passedFunction(this.props.index, "sad"); this.onLeave();}}>
                   <div class="label">Sad</div>
                    <img src={sad} />
                </div>
                <div class="item angry" onClick={() => {this.props.passedFunction(this.props.index, "angry"); this.onLeave();}}>
                <div class="label">Angry</div>
                    <img src={angry} />
                </div>
             </div>
            <div class='button like' onClick={() => {this.props.passedFunction(this.props.index, "like"); this.onLeave();}}>
                <img src={likeclean} /> 
                <label>Like</label>
            </div>
            <div class='button dislike' onClick={() => {this.props.passedFunction(this.props.index, "dislike"); this.onLeave();}}>
                <img src={likeclean} /> 
                <label>Dislike</label>
            </div>
        </div>
        }
        </div>

        );
    }
  }
  const mapStateToProps = state => {
    return {
        siteWidth: state.globalStates.siteWidth,
        siteHeight: state.globalStates.siteHeight,
        siteSizeSet: state.globalStates.siteSizeSet
    };
  }
  export default connect(mapStateToProps)(Box);