import {ReactComponent as Recycle} from './recycle.svg';
import {ReactComponent as Bin} from './bin.svg';
import {ReactComponent as Timeline} from './timeline2.svg';
import {ReactComponent as Marketplace} from './marketplace2.svg';
import { BrowserRouter as Router, Link, NavLink } from 'react-router-dom';
function Bar() {
  return (
    <div className="bar">
      <NavLink to="/feed" activeClassName="bar_active">
            <div class="icon recycle">
            <div class="mask">
              <Recycle />
              <div class="bgbox" />
              <div class="borderbox" />
            </div>
              <div class="titlebox">Feed</div>
            </div>
            </NavLink>
            <NavLink to="/marketplace" activeClassName="bar_active">
            <div class="icon marketplace">
            <div class="mask">
              <Marketplace />
              <div class="bgbox" />
              <div class="borderbox" />
            </div>
              <div class="titlebox">Marketplace</div>
            </div>
            </NavLink>
            <NavLink to="/timeline" activeClassName="bar_active">
            <div class="icon timeline">
            <div class="mask">
              <Timeline />
              <div class="bgbox" />
              <div class="borderbox" />
            </div>
              <div class="titlebox">Timeline</div>
            </div>
            </NavLink>
    </div>
  );
}

export default Bar;