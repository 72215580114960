import React, { PureComponent } from 'react';
import { Label, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import like from './png/like.png'
export default class Stat1 extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';
  render() {
    
    return (
      <div className="stats_container">
      <ResponsiveContainer>
        <LineChart
          width={100}
          height={300}
          data={[...this.props.data]}
          dataKey={"reactions"}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <Line type="monotone" dataKey="reactions" dot={false} stroke="#8884d8" strokeWidth={1}/>
        </LineChart>
        </ResponsiveContainer>
</div>
    );
  }
}